import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { toast } from 'vue3-toastify'
import { useAuthStore } from '@src/store/auth.ts'
import router from '@src/router'

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL ?? 'https://api.papaloves.ru',
  headers: {
    'User-Agent': 'WebApp/1.1',
  },
})

api.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()

    if (authStore.isAuth) {
      config.headers['Authorization'] = 'Bearer ' + authStore.accessToken
    }

    return config
  },
  (error) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Request:', error)
    }

    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    if (response.config.responseType === 'blob') {
      return response.data
    }

    if (!response.config.silent && response.data.message) {
      toast.success(response.data.message)
    }

    return response.data
  },
  (error: AxiosError<{ message?: string[] | string }>) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Response:', error.response)
    }

    if (error.response) {
      if (error.response.data?.message) {
        let text = Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message

        if (error.response.status >= 500) {
          text = 'Системная ошибка. Повторите позже'
        }

        if (
          error.config?.silent === undefined ||
          error.config?.silent === false
        ) {
          toast.error(text)
        }
      }

      if (error.response.status === 401) {
        useAuthStore().clearAccessToken()
        router.replace({ name: 'login' }).then()
      }
    }

    return Promise.reject(error)
  },
)

function request<T>(config: AxiosRequestConfig<any>): Promise<T> {
  return api(config)
}

export { api, request }

declare module 'axios' {
  export interface AxiosRequestConfig {
    silent?: boolean
  }
}
