<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import WaveSurfer from 'wavesurfer.js'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import Diary from '@src/components/Diary.vue'
import loadSecImage from '@src/loadSecImage.ts'
import { DiaryType } from '@src/api/diaries.ts'
import imgArchive from '@src/assets/img/img-archive.gif'
import Popup from '@src/components/Popup.vue'

let axiosController: AbortController

const authStore = useAuthStore()
const diariesList = ref<Record<number, typeof Diary>>({})
const diaries = ref<DiaryType[]>([])
const diariesNonDraftCount = ref(0)
const parentAvatar = ref('')
const isLoadingDiaries = ref(false)
const showArchive = ref(false)
const archivePercent = ref(0)
const currentAudioPlayer = ref<WaveSurfer | null>(null)

const currentPage = ref(1)
const totalPages = ref(1)

const setItemRef = (el: any, id: number) => {
  diariesList.value[id] = (el as typeof Diary)
}

const loadDiaries = (page = 1) => {
  isLoadingDiaries.value = true
  currentPage.value = page

  if (page === 1) {
    diaries.value = []
    diariesList.value = []
  }

  api.diaries
    .list('', page)
    .then((data) => {
      totalPages.value = Math.ceil(data.total / 10)
      diariesNonDraftCount.value = data.total

      data.diaries.forEach((item) => {
        item.type = item.isDraft ? 'draft' : ''
        diaries.value.push(item)
      })

      if (page === 1) {
        nextTick(() => {
          window.scrollTo(0, 0)
        })
      }
    })
    .finally(() => {
      isLoadingDiaries.value = false
    })
}

const handleAudioStart = (player: WaveSurfer) => {
  if (currentAudioPlayer.value !== null) {
    currentAudioPlayer.value.pause()
  }

  currentAudioPlayer.value = player
}

const handleAudioFinish = (id: number) => {
  currentAudioPlayer.value = null
  let isFind = false

  for (let diary of diaries.value) {
    if (!isFind && diary.id === id) {
      isFind = true
      continue
    }

    if (isFind && diary.files && diary.files.length > 0 && diary.files[0].fileType === 'audio') {
      const currentDiary = document.querySelector<HTMLElement>(`[data-diary="${diary.id}"]`)

      if (currentDiary) {
        window.scrollTo({
          top: currentDiary.offsetTop,
          behavior: 'smooth',
        })
        diariesList.value[diary.id].play()
      }

      break
    }
  }
}

const downloadArchive = () => {
  axiosController = new AbortController()
  archivePercent.value = 0
  showArchive.value = true

  api.diaries
    .getArchive(axiosController.signal, (progressEvent) => {
      if (progressEvent.total) {
        archivePercent.value = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100,
        )
      } else {
        archivePercent.value = -1
      }
    })
    .then((data) => {
      showArchive.value = false
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.zip')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
    .catch(() => {
      showArchive.value = false
    })
}

const handleWindowScroll = () => {
  if (
    !isLoadingDiaries.value &&
    currentPage.value < totalPages.value &&
    document.body.scrollHeight - 200 < document.documentElement.scrollTop + window.innerHeight
  ) {
    loadDiaries(currentPage.value + 1)
  }
}

onMounted(() => {
  loadDiaries()
  parentAvatar.value = ''

  if (authStore.parent && authStore.parent.avatar !== null) {
    parentAvatar.value = loadSecImage(authStore.parent.avatar, 'thumbnail')
  }

  window.addEventListener('scroll', handleWindowScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleWindowScroll)
})
</script>

<template>
  <main>
    <div class="header">
      <div class="header--count">
        <div class="emotions-ledger"></div>
        <div class="header--count--text">
          <span class="public">
            {{ diariesNonDraftCount }}
            {{
              $pluralize(diariesNonDraftCount, ['запись', 'записи', 'записей'])
            }}
          </span>
          <br />
          <span
            class="header--count--archive"
            @click="downloadArchive"
          >
            скачать архив
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="isLoadingDiaries || diaries.length > 0"
      class="diaries--wrapper"
    >
      <div class="diaries">
        <Diary
          v-for="(diary, key) in diaries"
          :key="key"
          :ref="(el) => setItemRef(el, diary.id)"
          :item="diary"
          :author-avatar-data="parentAvatar"
          is-enabled
          can-reaction
          @audio-start="handleAudioStart"
          @audio-finish="handleAudioFinish"
        />

        <img
          v-if="isLoadingDiaries"
          class="diaries-loading"
          src="../../assets/img/loading.svg"
          alt="Loading"
        />
      </div>
    </div>
  </main>

  <Popup
    v-model="showArchive"
    :image="imgArchive"
    @after-close="() => axiosController.abort()"
  >
    <h3 class="h1 only-desktop">Загрузка!</h3>
    <h3 class="h2 only-mobile">Загрузка!</h3>

    <h5
      v-if="archivePercent > 0"
      class="h2"
    >
      {{ archivePercent }}%
    </h5>

    <div class="content">
      <div class="emotions-hourglass"></div>
      <template v-if="archivePercent === 0">
        Подготовка архива для скачивания ...
      </template>
      <template v-else> Архив подготовлен и скачивается ...</template>
    </div>

    <div
      class="btn primary"
      @click="showArchive = false"
    >
      Отмена
    </div>
  </Popup>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    &--text {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      opacity: 0.8;

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
      }
    }

    &--archive {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      color: #2f80ed;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}

.emotions-ledger {
  --size: 26;

  @media screen and (min-width: $desktop) {
    --size: 32;
  }
}

.diaries {
  margin-top: 10px;
  padding: 10px 0 62px;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
  }
}

.diaries-loading {
  margin: 20px auto 0;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    width: 80px;
  }
}

.popup--content {
  h3 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;

    &.blue {
      color: #0da7f3;
    }

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  h5 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;
    color: #0da7f3;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    > div {
      position: relative;
      top: 3px;
      display: inline-block;
      --size: 20;
    }
  }

  .btn {
    margin-top: 40px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }
}
</style>
