<script setup lang="ts">
const model = defineModel()
const emit = defineEmits<{
  close: [boolean]
}>()

const btnDisagree = () => {
  emit('close', false)
  model.value = false
}

const btnAgree = () => {
  emit('close', true)
  model.value = false
}
</script>

<template>
  <div
    v-if="model"
    class="terms-wrapper"
  >
    <div class="terms">
      <div class="terms--content">
        <div class="terms--scroll">
          <div class="terms--title h2">Условия</div>

          <div class="terms--subtitle">отметьте ознакомление и согласие</div>

          <div class="terms--blocks">
            <div class="terms--block">
              <h4>Условия и соглашения PapaLoves</h4>

              <h5>1. Общие положения Пользовательского соглашения</h5>

              <p>
                1.1. В настоящем документе и вытекающих или связанным с ним
                отношениях Сторон применяются следующие термины и
                определения:<br />
                а) Платформа - программно-аппаратные средства, интегрированные с
                Сайтом Администрации;<br />
                б) Пользователь - дееспособное физическое лицо, присоединившееся
                к настоящему Соглашению в собственном интересе либо выступающее
                от имени и в интересах представляемого им юридического лица.<br />
                в) Сайт Администрации/ Сайт - интернет-сайты, размещенные в
                домене papaloves.ru и его поддоменах.<br />
                г) Сервис - комплекс услуг и лицензия, предоставляемые
                Пользователю с использованием Платформы.<br />
                д) Соглашение - настоящее соглашение со всеми дополнениями и
                изменениями.
              </p>

              <p>
                1.2. Использование вами Сервиса любым способом и в любой форме в
                пределах его объявленных функциональных возможностей,
                включая:<br />
                просмотр размещенных на Сайте материалов;<br />
                регистрация и/или авторизация на Сайте,<br />
                размещение или отображение на Сайте любых материалов, включая но
                не ограничиваясь такими как: тексты, гипертекстовые ссылки,
                изображения, аудио и видео- файлы, сведения и/или иная
                информация, создает договор на условиях настоящего Соглашения в
                соответствии с положениями ст.437 и 438 Гражданского кодекса
                Российской Федерации.
              </p>

              <p>
                1.3. Воспользовавшись любой из указанных выше возможностей по
                использованию Сервиса вы подтверждаете, что:<br />
                а) Ознакомились с условиями настоящего Соглашения в полном
                объеме до начала использования Сервиса.<br />
                б) Принимаете все условия настоящего Соглашения в полном объеме
                без каких-либо изъятий и ограничений с вашей стороны и
                обязуетесь их соблюдать или прекратить использование Сервиса.
                Если вы не согласны с условиями настоящего Соглашения или не
                имеете права на заключение договора на их основе, вам следует
                незамедлительно прекратить любое использование Сервиса.<br />
                в) Соглашение (в том числе любая из его частей) может быть
                изменено Администрацией без какого-либо специального
                уведомления. Новая редакция Соглашения вступает в силу с момента
                ее размещения на Сайте Администрации либо доведения до сведения
                Пользователя в иной удобной форме, если иное не предусмотрено
                новой редакцией Соглашения.
              </p>

              <h5>2. Условия пользования по Соглашению</h5>

              <p>
                2.1. Использование функциональных возможностей Сервиса
                допускается только после прохождения Пользователем регистрации и
                авторизации на Сайте в соответствии с установленной
                Администрацией процедурой.
              </p>

              <p>
                2.2. Технические, организационные и коммерческие условия
                использования Сервиса, в том числе его функциональных
                возможностей доводятся до сведения Пользователей путем
                отдельного размещения на Сайте или путем нотификации
                Пользователей
              </p>

              <p>
                2.3. Выбранные Пользователем логин и пароль являются необходимой
                и достаточной информацией для доступа Пользователя на Сайт.
                Пользователь не имеет права передавать свои логин и пароль
                третьим лицам, несет полную ответственность за их сохранность,
                самостоятельно выбирая способ их хранения.
              </p>

              <h5>
                3. Лицензия на использование Сайта и допустимое использование
                Сервиса
              </h5>

              <p>
                Пользователь в праве использовать сервис как дневник, для
                сохранения своих записок. Пользователь может, создавать доступ к
                своему дневнику и передавать его третьим лицам, только для
                прочтения. Также владелец дневника может произвести удаление
                записок и профиля, в любое время.
              </p>

              <h5>4. Права и обязанности сторон</h5>

              <p>4.1. Правообладатель обязуется:</p>

              <p>
                4.1.1. Информировать по вопросам работы с Сайтом посредством
                электронной почты, форума, блога.<br />
                Актуальные контакты службы поддержки размещаются по адресу:
                <a
                  href="https://papaloves.ru"
                  target="_blank"
                  >https://papaloves.ru</a
                >
              </p>

              <p>
                4.1.2. Консультировать Пользователя по всем вопросам, касающимся
                использования Сайта.
              </p>

              <p>4.2. Пользователь обязуется:</p>

              <p>
                4.2.1. Не использовать Сайт как объект интеллектуальных прав
                каким-либо способом, неразрешенным Соглашением, в частности,
                запрещается: воспроизводить Сайт, создавая копии на любом
                материальном носителе, модифицировать, вносить какие-либо
                изменения в Сайт и его части; использовать Сайт для создания
                производных программных продуктов; распространять Сайт любым
                способом; удалять или изменять знаки охраны авторских прав;
                пытаться обойти технические ограничения (технические меры защиты
                авторских прав) и использовать Сайт каким-либо иным образом,
                прямо непредусмотренным Соглашением; запрещается
                декомпилировать, разбирать или каким-либо другим способом
                пытаться извлечь исходный код программного обеспечения,
                являющегося элементом Сайта; использовать без специального на то
                разрешения автоматизированные скрипты (программы,боты, краулеры)
                для сбора информации на Сайте и/или взаимодействия с Сайтом и
                его функционалом; удалять или изменять любой торговый знак,
                логотип, знак охраны авторского права и прочие подобные
                уведомления на Сайте; запрещается распространять, продавать,
                сублицензировать, использовать программное обеспечение,
                являющееся элементом Сайта, или каким-либо другим способом
                передавать права на такое программное обеспечение; не
                использовать программное обеспечение и не осуществлять действия,
                направленные на нарушение нормального функционирования Сайта.
              </p>

              <p>
                4.2.2. Не загружать, не хранить, не публиковать, не
                распространять и не предоставлять доступ или иным образом
                использовать любую информацию, которая: содержит угрозы,
                дискредитирует, оскорбляет, порочит честь и достоинство или
                деловую репутацию или нарушает неприкосновенность частной жизни
                других Пользователей или третьих лиц;нарушает права
                несовершеннолетних лиц;является вульгарной или непристойной,
                содержит порнографические изображения и тексты или сцены
                сексуального характера с участием несовершеннолетних; содержит
                сцены бесчеловечного обращения животными;содержит описание
                средств и способов суицида, любое подстрекательство к его
                совершению;пропагандирует и/или способствует разжиганию расовой,
                религиозной, этнической ненависти или вражды, пропагандирует
                фашизм или идеологию расового превосходства; содержит
                экстремистские материалы; пропагандирует преступную деятельность
                или содержит советы, инструкции или руководства по совершению
                преступных действий; содержит информацию ограниченного доступа,
                включая, но не ограничиваясь, государственной и коммерческой
                тайной, информацией о частной жизни третьих лиц; содержит
                рекламу или описывает привлекательность употребления
                наркотических веществ, в том числе &laquo;цифровых
                наркотиков&raquo; (звуковых файлов, оказывающих воздействие на
                мозг человека за счет бинауральных ритмов), информацию о
                распространении наркотиков, рецепты их изготовления и советы по
                употреблению; потенциально может привести к совершению
                противоправных действий путем введения Пользователей в
                заблуждение или злоупотребления их доверием; нарушает иные права
                и интересы граждан и юридических лиц или требования
                законодательства Российской Федерации.
              </p>

              <p>
                4.2.3. Соблюдать имущественные и личные неимущественные права
                третьих лиц, включая авторские и иные права, права на
                использование изображений людей, размещать или распространять на
                Сайте интеллектуальную собственность Пользователей и третьих лиц
                только с соблюдением всех положений закона.
              </p>

              <p>4.3. Правообладатель вправе:</p>

              <p>
                4.3.1. Удалить Учетную запись Пользователя в случае нарушения им
                условий Соглашения или совершении любых неправомерных действий.
              </p>

              <p>
                4.3.2. Собирать (автоматически) информацию, не содержащую
                персональных сведений и не позволяющую идентифицировать
                пользователя, в целях распознавания предпочтения посетителей,
                пользующихся особой популярностью разделов Сайта. Собранная
                информация не может идентифицировать Пользователя. Пользователь
                в сможет в любое время заблокировать функцию сбора и анализа
                статистики данных, связанных с посещением Сайта.
              </p>

              <p>
                4.3.3. Удалять пользовательский контент по требованию
                уполномоченных органов или заинтересованных лиц в случае, если
                данный контент нарушает законодательство или права третьих лиц.
              </p>

              <p>
                4.3.4. В любой момент прекратить предоставление доступа к Сайту,
                а равно частично ограничивать или прекращать работу некоторых
                функций или сервисов, в том числе, в целях проведения
                технических работ на Сайте.
              </p>

              <p>4.4. Запрещается использовать сервис PapaLoves:</p>

              <p>
                В целях распостранения рекламы и обмана других пользователей
              </p>

              <p>
                4.4.1. Размещение в профиле материалов запрещенных законом РФ
              </p>

              <p>
                4.4.2. Пользователь, несет ответственность нарушения
                законодательства РФ и прав третьих лиц в связи с публикацией на
                сайте противоправных материалов.
              </p>

              <p>
                Администрация, имеет право удалить профили, нарушающие условия,
                без предупреждения, и запретить в будущем создавать учетные
                записи на платформе.
              </p>

              <h5>5. Лицензия на использование пользовательского контента</h5>

              <p>5. Условия Лицензии Пользователя</p>

              <p>
                5.1. Пользователю предоставляется Лицензия на использование
                Сайта в объеме и порядке, установленном Соглашением, без права
                предоставления сублицензий.
              </p>

              <p>
                5.2. Лицензия предусматривает следующие способы использования
                Сайта:
              </p>

              <p>5.2.1. Использование доступного функционала Сайта.</p>

              <p>
                5.2.2. Ведение дневника, удаление дневника и его содержимого,
                передача доступа третьим лицам (в ч. своим детям)
              </p>

              <p>
                5.3. Никакие иные права Пользователю не предоставляются В
                частности, Пользователь не вправе совершать модификацию,
                переработку, распространение и иные действия.
              </p>

              <p>
                5.4. В каждый момент времени Сайт предоставляется &laquo;как
                есть&raquo;. Правообладатель не гарантирует, что Сайт
                соответствует или будет соответствовать требованиям
                Пользователя, что доступ к Сайту будет предоставляться
                непрерывно, быстро, надежно и без ошибок.
              </p>

              <p>
                5.5. Пользователь гарантирует, что имеет все необходимые права
                на публикуемые материалы (пользовательский контент), которые
                позволяют разместить материал на Сайте и использовать его в
                дальнейшем с учетом функций Сайта. Пользователь самостоятельно
                несет ответственность за соответствие содержания размещаемого
                контента требованиям действующего законодательства, включая
                ответственность перед третьими лицами в случаях, когда
                размещение контента или содержание контента нарушает права и
                законные интересы третьих лиц, в том числе личные
                неимущественные права авторов, иные интеллектуальные права
                третьих лиц, и/или посягает на принадлежащие им нематериальные
                блага. В случае если какое-либо третье лицо предъявляет
                Правообладателю претензию в связи с нарушением Пользователем
                действующих законодательных норм, нарушением прав третьих лиц (в
                том числе прав на интеллектуальную собственность), Пользователь
                обязуется компенсировать Правообладателю все расходы и потери, в
                том числе оплатить любые компенсации и прочие затраты, связанные
                с такой претензией.
              </p>

              <p>
                5.6. Пользователь безвозмездно предоставляет Правообладателю
                простую неисключительную лицензию на использование
                Правообладателем Пользовательского контента как с указанием, так
                и без указания имени автора, без обязанности предоставлять
                отчеты об использовании, без необходимости получения
                специального разрешения Пользователя и без выплаты авторского
                вознаграждения, на территории всего мира, с правом
                Правообладателя предоставить указанные права использования
                Пользовательского контента третьим лицам. При этом Пользователь
                признает и соглашается, что Правообладатель не обязан
                просматривать Пользовательский контент, а его использование
                может осуществляться автоматически посредством программных
                средств. Правообладатель вправе использовать Пользовательский
                контент любым способом на любых информационных носителях,
                включая: воспроизведение путем записи в память ЭВМ;
                распространение, переработка, доведение до всеобщего сведения,
                предоставление права использования Пользовательского контента
                третьим лицам; включение в состав рекламных материалов,
                направленных на продвижение услуг и продуктов Правообладателя
                путем включения в сложное или составное произведение.
                Впоследствии такие рекламные материалы с включенным
                Пользовательским контентом могут использоваться следующими
                способами: воспроизведение, распространение, доведение до
                всеобщего сведения, публичный показ, сообщение по кабелю или в
                эфир. Правообладатель вправе использовать Пользовательский
                контент на Сайте, в иных своих сервисах и приложениях, в
                рекламных или маркетинговых материалах, размещаемых на любых
                ресурсах. Лицензия действует без ограничения срока. При удалении
                Пользовательского контента с Сайта Правообладатель вправе
                сохранять архивные копии Пользовательского контента и не изымать
                из оборота созданные материалы, которые его содержат.
              </p>

              <h5>6. Ответственность сторон</h5>

              <p>
                7.1. Стороны несут ответственность за неисполнение или
                ненадлежащее исполнение своих обязательств в соответствии с
                условиями Соглашения и законодательством России.
              </p>

              <p>
                7.2. Правообладатель не принимает на себя ответственность за
                соответствие Сайта целям использования.
              </p>

              <p>
                7.3. Правообладатель не несет ответственности за технические
                перебои в работе Сайта. Вместе с тем Правообладатель обязуется
                принимать все разумные меры для предотвращения таких перебоев.
              </p>

              <p>
                7.4. Правообладатель не гарантирует, что Сайт будет
                соответствовать требованиям Пользователя, а также не гарантирует
                работоспособность Сайта совместно с программным обеспечением и
                оборудованием других изготовителей; не гарантирует, что Сайт не
                содержит ошибки, не гарантирует бесперебойную работу Сайта, не
                гарантирует сохранность данных Пользователя; не несет
                ответственности за результаты, полученные при использовании
                Сайта, не несет ответственности за прямые или косвенные убытки
                любого рода, понесенные вследствие использования либо
                неиспользования Сайта.
              </p>

              <p>
                7.5. Правообладатель не имеет технической и фактической
                возможности проверять всю информацию, размещаемую Пользователем,
                на Сайте, на предмет ее соответствия требованиям
                законодательства Российской Федерации и положениям Соглашения,
                поскольку подобная проверка сделает невозможным функционирование
                Сайта. Правообладатель вправе предпринимать действия по проверке
                такого контента по собственному усмотрению. Правообладатель не
                может гарантировать соответствие такого контента требованиям
                законодательства Российской Федерации и отсутствие нарушений
                прав и законных интересов третьих лиц.
              </p>
            </div>
          </div>
        </div>

        <div class="terms--overlay"></div>
      </div>

      <div class="terms--buttons">
        <div
          class="btn"
          @click="btnDisagree"
        >
          Нет
        </div>

        <div
          class="btn primary"
          @click="btnAgree"
        >
          Согласен
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.terms-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 102;
}

.terms {
  position: absolute;
  top: 50px;
  bottom: 35px;
  left: 15px;
  right: 15px;
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: $desktop) {
    top: 125px;
    bottom: 125px;
    left: 120px;
    right: 120px;
    margin: 0 auto;
    padding: 50px 60px 60px;
    max-width: 1200px;
    max-height: 770px;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    box-sizing: border-box;
  }

  &--content {
    position: relative;
    padding: 20px 0;
    min-height: 0;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    overflow: hidden;

    @media screen and (min-width: $desktop) {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &--scroll {
    padding: 0 20px 60px;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;

    @media screen and (min-width: $desktop) {
      padding: 0 15px 80px;
    }
  }

  &--title {
    margin-bottom: 16px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      font-size: 48px;
      font-weight: 600;
    }
  }

  &--subtitle {
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    @media screen and (min-width: $desktop) {
      font-size: 15px;
      font-weight: 500;
    }
  }

  &--blocks {
    @media screen and (min-width: $desktop) {
      margin-top: 50px;
      display: flex;
      flex-flow: row nowrap;
      gap: 50px;
    }
  }

  &--block {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;

    @media screen and (min-width: $desktop) {
      margin-top: 0;
      font-size: 17px;
      font-weight: 500;
      line-height: 120%;
    }

    h4 {
      margin-bottom: 16px;
      color: #015bb0;

      @media screen and (min-width: $desktop) {
        font-size: 32px;
        font-weight: 600;
      }
    }

    h5 {
      margin: 1.5em 0 1em;
      font-size: 14px;
      font-weight: 700;

      @media screen and (min-width: $desktop) {
        font-size: 17px;
      }
    }
  }

  &--overlay {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 1) 80%
    );

    @media screen and (min-width: $desktop) {
      left: 10px;
      right: 18px;
    }
  }

  &--buttons {
    flex: 0 0 auto;
    margin: 20px auto 0;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    width: 335px;
    max-width: 100%;
    height: 52px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }

    .btn {
      box-shadow: 0 20px 40px 0 #cad9ef66;
    }
  }
}
</style>
